<template>
  <div class="invoice-history">
    <invoice-header text="开票历史" return-route-name="invoice"></invoice-header>
    <div class="invoice-history__list">
      <div class="invoice-history__tab">
        <div class="invoice-history__tab__wrap">
          <div
            :class="{'invoice-history__tab__item':true,'activeLi':activeIndex===0}"
            @click="onTabClick(0)"
          >开票中</div>
          <div
            :class="{'invoice-history__tab__item':true,'activeLi':activeIndex===1}"
            @click="onTabClick(1)"
          >开票成功</div>
          <div
            :class="{'invoice-history__tab__item':true,'activeLi':activeIndex===2}"
            @click="onTabClick(2)"
          >开票失败</div>
        </div>
      </div>
      <div
        class="invoice-history__list__item"
        v-for="(order,index) in historyOrder"
        :key="index"
        @click="onItemClick(order)"
        :data-fpqqlsh="order.fpqqlsh"
      >
        <ul>
          <li>
            <span>{{order.time}}</span>
            <span>{{order.state | parseInvoiceState}}</span>
          </li>
          <li>
            <span>电子发票</span>
            <span class="price">{{order.amount}}元</span>
          </li>
        </ul>
      </div>
      <mugen-scroll :handler="fetchData" :should-handle="!loading">{{promptText}}</mugen-scroll>
    </div>
  </div>
</template>

<script>
import { getHistory, getSuccessHistory, getFailHistory } from "./invoice";
import util from "../../commons/util";

export default {
  components: {
    "invoice-header": require("./header").default,
  },
  created() {},
  data() {
    return {
      loading: false,
      activeIndex: 0,
      promptText: "",
      pageSize: 10, // 单页显示数
      dataList: {
        0: { currentPage: 1, data: [] },
        1: { currentPage: 1, data: [] },
        2: { currentPage: 1, data: [] },
      },
    };
  },
  computed: {
    historyOrder() {
      return this.dataList[this.activeIndex].data;
    },
  },
  filters: {
    parseInvoiceState(val) {
      let res = "";
      switch (val) {
        case "2":
          res = "开票成功";
          break;
        case "3":
          res = "发票已作废";
          break;
        case "20":
        case "21":
          res = "开票中";
          break;
        case "22":
        case "24":
          res = "开票失败";
          break;
        case "31":
          res = "发票作废中";
          break;
      }
      return res;
    },
  },
  methods: {
    onTabClick: function (index) {
      this.activeIndex = index;
      this.fetchData();
    },
    onItemClick(order) {
      if (order.isHc == "1") {
        util.toast("发票已作废");
        return;
      }
      sessionStorage.setItem("InvoiceDetail", JSON.stringify(order));
      this.$router.push({ name: "invoice_detail" });
    },
    async fetchData() {
      this.loading = true;
      this.promptText = "加载中...";

      let res = null;
      let currentPage=this.dataList[this.activeIndex].currentPage;
      if (this.activeIndex === 0) {
        res = await getHistory(currentPage, this.pageSize);
      } else if (this.activeIndex === 1) {
        res = await getSuccessHistory(currentPage, this.pageSize);
      } else {
        res = await getFailHistory(currentPage, this.pageSize);
      }

      if (res.length === 0) {
        this.promptText = "没有更多数据了";
      } else {
        this.dataList[this.activeIndex].currentPage += 1;
        this.dataList[this.activeIndex].data.push(...res);

        this.promptText = "";
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.invoice-history__tab {
  width: 85%;
  height: 0.5rem;
  margin: 0.2rem auto;
  text-align: center;
  position: relative;

  &:before {
    content: "";
    width: 2px;
    height: 1rem;
    display: none;
    line-height: 1rem;
    background: #ccc;
    margin: 0 auto;
    position: absolute;
    top: 0;
  }
  &__wrap {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
  }
  &__item {
    font-size: 0.229rem;
    display: inline-block;
    width: 33%;
    float: left;
    line-height: 0.5rem;
  }
}

.activeLi {
  background: #20a3dc;
  color: #fff;
  border-radius: 0.5rem;
}

.invoice-history {
  &__list {
    font-size: 0.229rem;
    line-height: 0.5rem;
    color: #666666;

    &__item {
      padding: 0.45rem 0.9rem 0.45rem 0.39rem;
      border-bottom: 0.02rem solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
      width: 100%;
      position: relative;

      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        margin-top: -0.105rem;
        right: -0.38rem;
        width: 0.12rem;
        height: 0.21rem;
        background-image: url("./images/right-arrow.png");
        background-repeat: no-repeat;
        background-size: cover;
      }

      li {
        &:before {
          content: "";
          display: inline-block;
          width: 0.07rem;
          height: 0.07rem;
          margin-right: 0.37rem;
          border-radius: 50%;
          vertical-align: middle;
          background-color: #666666;
        }

        span:last-child {
          float: right;
        }

        .price {
          font-size: 0.333rem;
          line-height: 0.48rem;
          color: #1394cc;
        }
      }
    }

    .mugen-scroll {
      font-size: 0.25rem;
      line-height: 2;
      color: #333333;
      text-align: center;
    }
  }
}
</style>
